<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <b-form-group
            id="input-group-user2"
            label="Peminjam:"
            label-for="input-user2"
          >
            <b-form-input
              id="input-user2"
              v-model="display.user_name"
              placeholder="Pilih Peminjam"
              readonly
              @click="$bvModal.show('modal-user2')"
            ></b-form-input>
            <small class="text-danger">{{ error.user_id }}</small>
          </b-form-group>

          <!-- <b-form-group
            id="input-group-tool"
            label="Alat:"
            label-for="input-tool"
          >
            <b-form-input
              id="input-tool"
              v-model="display.tool_name"
              placeholder="Pilih Alat"
              readonly
              @click="$bvModal.show('modal-tool')"
            ></b-form-input>
            <small class="text-danger">{{ error.tool_id }}</small>
          </b-form-group> -->

          <div class="row">
            <div class="col-sm-12">
              <b-button
                variant="info"
                block
                @click="$bvModal.show('modal-tool-multiple')"
              >
                Tambah Alat
              </b-button>
            </div>
            <div class="col-sm-12 pt-2">
              <table class="table thead-light">
                <thead class="thead-light">
                  <tr>
                    <th>No</th>
                    <th>Alat</th>
                    <th></th>
                  </tr>
                </thead>
                <tr v-for="(tool, i) in toolCollection" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ tool.name }}</td>
                  <td>
                    <b-button
                      class="ml-2"
                      variant="danger"
                      v-b-tooltip.hover
                      title="Hapus"
                      @click="deleteTool(i)"
                      size="sm"
                    >
                      <i class="fas fa-trash px-0"></i>
                    </b-button>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <b-button
                variant="info"
                block
                @click="$bvModal.show('modal-lab-material-multiple')"
              >
                Tambah Bahan
              </b-button>
            </div>
            <div class="col-sm-12 pt-2">
              <table class="table thead-light">
                <thead class="thead-light">
                  <tr>
                    <th>No</th>
                    <th>Bahan</th>
                    <th>Jumlah</th>
                    <th></th>
                  </tr>
                </thead>
                <tr v-for="(labMaterial, i) in labMaterialCollection" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ labMaterial.name }}</td>
                  <td>{{ labMaterial.quantity }}</td>
                  <td>
                    <b-button
                      class="ml-2"
                      variant="success"
                      v-b-tooltip.hover
                      title="Edit Jumlah"
                      @click="editLabMaterial(i)"
                      size="sm"
                    >
                      <i class="fas fa-edit px-0"></i>
                    </b-button>
                    <b-button
                      class="ml-2"
                      variant="danger"
                      v-b-tooltip.hover
                      title="Hapus"
                      @click="deleteLabMaterial(i)"
                      size="sm"
                    >
                      <i class="fas fa-trash px-0"></i>
                    </b-button>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row" v-if="purpose == 'edit'">
            <div class="col">
              <b-form-group id="input-group-buy-date">
                <label for="input-buy-date">Tanggal Pinjam: </label>
                <v-menu
                  v-model="menuDate1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <b-form-input
                      id="input-date"
                      v-model="form.borrow_date"
                      label="Tanggal Pinjam:"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></b-form-input>
                  </template>
                  <v-date-picker
                    v-model="form.borrow_date"
                    @input="menuDate1 = false"
                  ></v-date-picker>
                </v-menu>
                <small class="text-danger">{{ error.borrow_date }}</small>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group id="input-group-inventory-date">
                <label for="input-inventory-date"
                  >Tanggal Kembali: <em class="text-muted">opsional</em></label
                >
                <v-menu
                  v-model="menuDate2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <b-form-input
                      id="input-date"
                      v-model="form.return_date"
                      label="Tanggal Kembali:"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></b-form-input>
                  </template>
                  <v-date-picker
                    v-model="form.return_date"
                    @input="menuDate2 = false"
                  ></v-date-picker>
                </v-menu>
                <small class="text-danger">{{ error.return_date }}</small>
              </b-form-group>
            </div>
          </div>

          <b-form-group id="input-group-buy-date" v-if="purpose == 'add'">
            <label for="input-buy-date">Tanggal Pinjam: </label>
            <v-menu
              v-model="menuDate1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-date"
                  v-model="form.borrow_date"
                  label="Tanggal Pinjam:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.borrow_date"
                @input="menuDate1 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.borrow_date }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-laboran"
            label="Petugas:"
            label-for="input-laboran"
          >
            <b-form-input
              id="input-laboran"
              v-model="display.laboran_name"
              placeholder="Pilih Petugas"
              readonly
              @click="$bvModal.show('modal-laboran')"
            ></b-form-input>
            <small class="text-danger">{{ error.officer_id }}</small>
          </b-form-group>

          <b-form-group id="input-group-description">
            <label for="input-description">Keterangan:</label>
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Keterangan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>

          <!-- <b-form-group label="Status:" v-slot="{ ariaDescribedby }" v-if="purpose == 'edit'">
            <b-form-radio-group
              id="radio-group-2"
              v-model="form.borrower_status_id"
              :aria-describedby="ariaDescribedby"
              name="radio-sub-component"
            >
              <template v-for="item in borrower_statuses">
                <b-form-radio
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</b-form-radio
                >
              </template>
            </b-form-radio-group>
          </b-form-group> -->
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/tool-borrowers/list')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
    <ModalUser
      id="modal-user2"
      purpose="modal-user2"
      @chooseData="chooseDataUser"
      :filterProps="filterModalUser"
      title="Pilih Peminjam"
    />
    <ModalLaboran
      id="modal-laboran"
      purpose="modal-laboran"
      @chooseData="chooseDataLaboran"
      :filterProps="filterModalLaboran"
      title="Pilih Petugas"
    />
    <ModalTool @chooseData="chooseDataTool" />
    <ModalToolMultiple
      @chooseTool="chooseTool"
      @unCheckTool="unCheckTool"
      :toolCollection="toolCollection"
    />
    <ModalLabMaterialMultiple
      @chooseLabMaterial="chooseLabMaterial"
      @unCheckLabMaterial="unCheckLabMaterial"
      :labMaterialCollection="labMaterialCollection"
    />

    <!-- Modal Quantity -->
    <b-modal
      id="modal-lab-material-quantity"
      @ok="addLabMaterial"
      @cancel="cancelModal"
    >
      <template #modal-title>Form Jumlah Bahan</template>
      <b-form-group
        id="input-group-quantity"
        label="Jumlah:"
        label-for="input-quantity"
      >
        <b-form-input
          id="input-quantity"
          v-model="labMaterialCollectionForm.quantity"
          placeholder="Jumlah"
          type="number"
          required
        ></b-form-input>
        <small class="text-danger">{{
          labMaterialCollectionError.quantity
        }}</small>
      </b-form-group>
    </b-modal>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalUser from "@/view/components/general/ModalUser2.vue";
import ModalLaboran from "@/view/components/general/ModalUser2.vue";
import ModalTool from "@/view/components/general/ModalTool.vue";
import ModalToolMultiple from "@/view/components/general/ModalToolMultiple.vue";
import ModalLabMaterialMultiple from "@/view/components/general/ModalLabMaterialMultiple.vue";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
  },
  components: {
    ModalUser,
    ModalLaboran,
    ModalTool,
    ModalToolMultiple,
    ModalLabMaterialMultiple,
  },
  data() {
    return {
      filterModalUser: {
        role_id: "",
      },
      filterModalLaboran: {
        role_id: 3,
      },
      formData: new FormData(),
      error: {
        name: "",
        serial_number: "",
        product_number: "",
        specification: "",
        tool_category_id: "",
        price: "",
        photo_url: "",
        tool_status_id: "",
        sop_usage: "",
        brand: "",
        buy_date: "",
        inventory_date: "",
        inventory_code: "",
        location: "",
      },
      current_photo: "/img/default/tool-default.svg",
      default_photo: "/img/default/tool-default.svg",
      tool_categories: [],
      menuDate1: false,
      menuDate2: false,
      display: {
        user_name: "",
        tool_name: "",
        laboran_name: "",
      },
      borrower_statuses: [],
      toolCollection: [],
      toolCollectionDeleted: [],
      toolCollectionForm: {
        id: "",
        name: "",
        borrower_status_id: 1,
        return_date: null,
        tool_status_id: 0,
      },
      toolPurpose: "add",
      labMaterialCollection: [],
      labMaterialCollectionDeleted: [],
      labMaterialCollectionForm: {
        id: "",
        name: "",
        quantity: "",
        remaining_stock: "",
      },
      labMaterialCollectionError: {
        id: "",
        name: "",
        quantity: "",
        remaining_stock: "",
      },
      labMaterialIndex: "",
      labMaterialPurpose: "add",
    };
  },
  methods: {
    // TOOL
    chooseTool(data) {
      this.toolPurpose = "add";
      this.toolCollectionForm.id = data.id;
      this.toolCollectionForm.name = data.name;
      let clone = { ...this.toolCollectionForm };
      this.toolCollection.push(clone);
    },
    unCheckTool(value) {
      for (let a = 0; a < this.toolCollection.length; a++) {
        if (this.toolCollection[a] == value.id) {
          this.toolCollection.splice(a, 1);
        }
      }
    },
    deleteTool(index) {
      this.toolCollectionDeleted.push(this.toolCollection[index]);
      this.toolCollection.splice(index, 1);
    },
    // LAB MATERIAL
    chooseLabMaterial(data) {
      this.labMaterialPurpose = "add";
      this.labMaterialCollectionForm.name = data.name;
      this.labMaterialCollectionForm.id = data.id;
      this.labMaterialCollectionForm.remaining_stock = data.remaining_stock;
      this.labMaterialCollectionForm.quantity = "";
      this.$bvModal.show("modal-lab-material-quantity");
    },
    validationLabMaterialQuantity() {
      let status = true;
      if (this.labMaterialCollectionForm.quantity == "") {
        status = false;
        this.labMaterialCollectionError.quantity = "Kolom jumlah wajib diisi";
      } else {
        this.labMaterialCollectionError.quantity = "";
      }

      if (
        this.labMaterialCollectionForm.quantity >
        this.labMaterialCollectionForm.remaining_stock
      ) {
        status = false;
        this.labMaterialCollectionError.quantity = `Stok bahan hanya tersisa ${this.labMaterialCollectionForm.remaining_stock}`;
      } else {
        this.labMaterialCollectionError.quantity = "";
      }

      return status;
    },
    addLabMaterial(bvModalEvt) {
      if (this.validationLabMaterialQuantity() == false) {
        bvModalEvt.preventDefault();
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        throw new Error("validation error");
      }

      if (this.labMaterialPurpose == "add") {
        if (!this.form.hasOwnProperty("oldLabMaterialCollectionFlag")) {
          this.setOldLabMaterialCollection();
        }
        let clone = { ...this.labMaterialCollectionForm };
        this.labMaterialCollection.push(clone);
        this.$toast.success("Berhasil tambah bahan");
      } else {
        if (!this.form.hasOwnProperty("oldLabMaterialCollectionFlag")) {
          this.setOldLabMaterialCollection();
        }
        this.$toast.success("Berhasil edit jumlah bahan");
        // this.labMaterialCollection[this.labMaterialIndex].name = // this.labMaterialCollection.name;
          this.labMaterialCollection[this.labMaterialIndex].quantity = this.labMaterialCollectionForm.quantity;
      }
      this.$bvModal.hide("modal-lab-material-quantity");
      this.resetLabMaterial();
    },
    cancelModal() {
      this.$root.$emit("cancelModal", this.labMaterialCollectionForm);
    },
    resetLabMaterial() {
      this.labMaterialCollectionForm.quantity = "";
    },
    unCheckLabMaterial(value) {
      for (let a = 0; a < this.labMaterialCollection.length; a++) {
        if (this.labMaterialCollection[a] == value.id) {
          this.labMaterialCollection.splice(a, 1);
        }
      }
    },
    deleteLabMaterial(index) {
      if (!this.form.hasOwnProperty("oldLabMaterialCollectionFlag")) {
        this.setOldLabMaterialCollection();
      }
      this.labMaterialCollection.splice(index, 1);
    },
    editLabMaterial(i) {
      this.labMaterialPurpose = "edit";
      this.labMaterialIndex = i;

      this.labMaterialCollectionForm.id = this.labMaterialCollection[i].id;
      this.labMaterialCollectionForm.name = this.labMaterialCollection[i].name;
      this.labMaterialCollectionForm.quantity =
        this.labMaterialCollection[i].quantity;
      this.labMaterialCollectionForm.remaining_stock =
        this.labMaterialCollection[i].remaining_stock;

      this.$bvModal.show("modal-lab-material-quantity");
    },
    setOldLabMaterialCollection() {
      this.form.oldLabMaterialCollectionFlag = JSON.stringify(this.form.lab_material_id);
      this.formData.append("oldLabMaterialCollection", JSON.stringify(this.form.lab_material_id));
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.photo_url = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.photo_url = "";
    },
    async getToolCategoryOption() {
      let response = await module.setTreeSelect("api/tool-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.tool_categories = response.data;
        this.tool_categories.unshift({
          label: "Pilih Kategori Alat",
          id: "",
          isDisabled: true,
        });
      }
    },
    chooseDataUser(value) {
      this.$bvModal.hide("modal-user2");
      this.form.user_id = value.id;
      this.display.user_name = value.name;
    },
    chooseDataLaboran(value) {
      this.$bvModal.hide("modal-laboran");
      this.form.officer_id = value.id;
      this.display.laboran_name = value.name;
    },
    chooseDataTool(value) {
      this.$bvModal.hide("modal-tool");
      this.form.tool_id = value.id;
      this.display.tool_name = value.name;
    },
    async getBorrowerStatus() {
      this.borrower_statuses = await module.get("api/borrower-statuses");
    },
    async formOnsubmit() {
      this.form.tool_id = JSON.stringify(this.toolCollection);
      this.form.lab_material_id = JSON.stringify(this.labMaterialCollection);

      if (this.purpose == "edit" && this.toolCollectionDeleted.length > 0) {
        this.form.toolCollectionDeleted = JSON.stringify(
          this.toolCollectionDeleted
        );
      }


      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );

        this.form.tool_id = JSON.parse(this.toolCollection);
        this.form.lab_material_id = JSON.parse(this.labMaterialCollection);
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push("/tool-borrowers");
      }
    },
  },
  mounted() {
    this.getToolCategoryOption();
    this.getBorrowerStatus();
  },
  computed: {
    formatedPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.price = newValue.toLocaleString("id-ID");
        } else {
          this.form.price = "";
        }
      },
      get() {
        return this.form.price;
      },
    },
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.form = this.form;

        this.default_photo = this.form.photo_url;
        this.current_photo = this.form.photo_url;

        this.display.user_name = this.form.user_name;
        this.display.laboran_name = this.form.officer_name;
        // this.display.tool_name = this.form.tool_name;

        this.toolCollection = this.form.tool_id;
        this.labMaterialCollection = this.form.lab_material_id;

        // this.form.oldLabMaterialCollection = this.form.lab_material_id
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 230px !important;
  height: 230px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>