<template>
  <div>
    <b-modal
      id="modal-tool"
      size="xl"
      title="Pilih Alat"
      hide-footer
    >
      <Table purpose="modal-tool" @chooseData="chooseData" />
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/tools/Table.vue";

export default {
  props: {},

  components: {
    Table,
  },

  methods: {
    chooseData(value) {
      this.$emit("chooseData", value);
    },
  },
};
</script>

<style>
</style>
